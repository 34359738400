<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 295px; width:600px">
        <field name="activo" widget="toggle" :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="75"
          inline-label style="top: 10px; right: 180px" searchable />
        <button class="button" style="position: absolute; top:10px; right: 10px;" @click="abrirSelector"
          :disabled="mode != 'edit' && mode != 'new'">Selector de
          artículos</button>
        <field name="imagen" widget="m2o_img" dbAdapter="imagen_articulo" label="Imagen"
          style="width:60px; height:60px; top: 10px; left:10px;" inputStyle="top:100%;left:0px;width:100%"
          closeButtonStyle="left: calc(100% - 15px);top:0;color:#666;cursor:pointer;" />
        <field name="codigo" widget="char" searchable label="Código" help="Código" placeholder="Cod" width="50px"
          :readonly="mode == 'edit'" style="top:15px; left:85px;" />
        <field name="nombre" widget="char" searchable label="Nombre" help="Descripción" placeholder="Descripción"
          width="400px" style="top:56px; left:85px;" />

        <field ref="articulos" name="articulos" widget="handsontable" label="Artículos" primary="codigo" :height="183"
          :width="400" style="top: 100px; left: 10px;" :minRows="7"
          :fields="[{ name: 'thumbnail', fields: ['imagen'] }]" :columns="[
          {
            name: 'ean13',
            header: '|||||||||',
          },
          {
            name: 'codigo',
            header: 'Código',
          },
          {
            name: 'nombre',
            header: 'Descripción',
            readOnly: true,
          },
          {
            name: 'familia_id',
            header: 'Familia',
            type: 'm2o',
            primary: 'codigo',
            label: 'nombre',
            showCode: true,
            readOnly: true,
          },
          {
            name: 'seccion_id',
            header: 'Sección',
            type: 'm2o',
            primary: 'codigo',
            label: 'nombre',
            showCode: true,
            readOnly: true,
          },
        ]" />
        <img v-if="selectedArticulo.thumbnail" :src="selectedArticulo.thumbnail.imagen"
          style="width:180px; height: 180px; position: absolute; top: 100px; right: 5px;" />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Colecciones",
      dbAdapter: "coleccion",
      primary: "codigo",
      sequence: { name: "coleccion" },
      selectedArticulo: {}
    };
  },
  methods: {
    abrirSelector() {
      var self = this;
      self.app.openChildWindow("selector_articulos_imagen", self.win, {
        backdrop: true,
        //resizable: true,
        props: {
          initialSelectedItems: self.formData.articulos && Array.isArray(self.formData.articulos) ? self.formData.articulos : [],
          fields: [
            'codigo',
            'ean13',
            'nombre',
            { name: 'seccion_id', fields: ['codigo', 'nombre'] },
            { name: 'familia_id', fields: ['codigo', 'nombre'] },
            { name: 'thumbnail', fields: ['imagen'] }
          ],
          onAccept: function (articulos) {
            self.selectedArticulo = {};
            if (!articulos || !articulos.length) {
              self.formData.articulos = false;
            } else {
              self.formData.articulos = articulos;
            }
          }
        },
      });
    },
  },
  mounted() {
    let self = this;
    self.$refs.articulos.field.hotInstance.addHook('afterChange', function (changes, source) {
      if (source == 'loadData') return;
      if (!changes) return;
      var h = this;
      changes.forEach(function (change, index) {
        if (!change) return;
        var row = change[0];
        var prop = change[1];
        var oldVal = change[2];
        var newVal = change[3];
        if (prop == 'ean13' || prop == 'codigo') {
          if (!newVal) {
            self.formData.articulos.splice(row, 1);
          } else {
            if (newVal.length == 12) newVal = '0' + newVal;
            if (prop == 'ean13' && newVal.includes(',')) {
              newVal = parseInt(newVal.replace('.', '')).toString();
              newVal = newVal.padStart(7, '0');
              const ean_checkdigit = (code) => {
                let sum = 0;
                for (let i = 0; i < code.length; i++) {
                  sum += (((i + 1) % 2) * 2 + 1) * code[code.length - 1 - i];
                }
                return code + (10 - (sum % 10)) % 10;
              }
              newVal = ean_checkdigit(newVal);
            }
            window.DB.getList('articulo', {
              limit: 1,
              filter: [prop == 'codigo' ? 'codigo' : 'ean13', "=", newVal],
              depth: 3,
              fields: [
                'codigo',
                'ean13',
                'nombre',
                { name: 'seccion_id', fields: ['codigo', 'nombre'] },
                { name: 'familia_id', fields: ['codigo', 'nombre'] },
                { name: 'thumbnail', fields: ['imagen'] }
              ]
            }).then(function (res) {
              if (parseInt(res.count)) {
                if (h.getSourceData().some((x, i) => i != row && x.codigo == res.data[0].codigo)) {
                  h.getSourceData()[row] = oldVal || {};
                  self.app.toast('Ya está añadido el artículo', 'error');
                  h.selectCell(row, prop);
                } else {
                  h.getSourceData()[row] = res.data[0];
                  self.selectedArticulo = res.data[0];
                  //h.setDataAtRowProp(row, '_op', null);
                }
              } else {
                h.getSourceData().splice(row, 1);
                self.app.toast('No se han encontrado el artículo', 'error');
                h.selectCell(row, prop);
              }
              h.render();
              h.setDataAtRowProp(row, '_op', null);

            });
          }
          //h.render();
        }
      });
    });

    self.$refs.articulos.field.hotInstance.addHook('afterSelectionEnd', function (row) {
      var h = this;
      let a = h.getSourceData()[row];

      self.selectedArticulo = a.codigo ? a : {};

    });

    self.$on(['loadItem', 'setMode'], (e) => {
      self.selectedArticulo = {};
    })
  }
};
</script>